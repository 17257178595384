.report-container {
  background: #2a303a;
  height: -webkit-fill-available;
  display: flex;
  color: #ffffff;
  flex-direction: column;
  padding: 1.5rem;
}

.report-heading {
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.report-info {
  font-size: 16px;
  font-weight: 400;
  color: #98a5ba;
}

.report-status {
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0.5rem;
}

.report-container .MuiIconButton-label {
    width: 18px;
    height: 18px;
}

.report-container .MuiIconButton-label svg{
  width: 20px;
}

.report-container .MuiIconButton-root {
    padding: 6px !important;
}

.date-label {
    margin-bottom: 0.5rem;
    font-size: 14px;
    font-weight: 400;
}

.reportBtnLoader {
  height: 48px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.report-loader {
  height: 58px;
  padding-bottom: 1.09rem;
}

.seperator {
    margin-left: 0.8rem;
    margin-right: 0.8rem;
    display: flex;
    align-items: center;
    padding-top: 1.2rem;
}

.report-status.active {
  color: #79e6ba;
}

.report-status.inactive {
  color: #ff7070;
}

.report-container .enable {
  color: #79e6ba;
}

.report-container .disable {
  color: #ff7070;
}

.report-note {
    font-size: 12px;
    font-weight: 500;
    color: #A3CBBB;
    margin-top: 0.5rem;
}

.reportError {
  color: #ff7070;
  font-size: 14px;
  font-weight: 600;
  margin-top: 0.5rem;
}

/* border: 1px solid red;
padding: 4px;
border-radius: 4px; */