.hslMainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  .topSection {
    display: flex;
    flex-direction: column;
    align-items: center;

    .colorPreview {
      width: 64px;
      height: 64px;
      border-radius: 5px;
      margin: 15px 0;
    }

    .colorHex {
      border: solid 1px #6b7684;
      border-radius: 5px;
      padding-top: 3px;
      margin: 0 10px;
      color: white;
      display: flex;
      justify-content: center;
    }
  }

  .colorPickerContainer {
    display: flex;
    border-radius: 1px;
    padding: 10px 0;

    .singleContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      height: 50vh;

      .sliderContainer {
        border-radius: 8px;
        margin: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }

      .title {
        margin: auto;
        text-align: center;
        padding: 10px 0;
        color: white;
      }

      .hue {
        background-image: linear-gradient(to top, red, orange, yellow, green, blue, indigo, violet);
      }
    }
  }
}
