$max-width: 700px;

.checkout-form-container {
  position: relative;
  color: #ffffff;
  width: 100%;
  max-width: $max-width;
  display: flex;
  flex: 1 1 auto;
  margin: 0 auto;
  flex-direction: column;
  overflow: auto;
  .form-fields-container {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    overflow: auto;
    padding: 0 15px 80px 15px;
  }
  .content-container {
    flex: 1 1 0;
    margin: 0 auto;
    display: flex;
    align-items: center;
    padding: 15px;
  }
  .form-field-action-btns {
    align-self: flex-end;
    z-index: 10;
    position: fixed;
    bottom: 0;
    display: flex;
    flex-direction: column;
    flex: 0 0 auto;
    align-items: end;
    width: 100%;
    max-width: $max-width;
    .add-button {
      margin: 15px;
      border-radius: 30px;
      padding: 10px 15px;
      font-weight: 600;
      box-shadow: -1px 2px 15px -1px rgba(0,0,0,0.4);
      svg {
        margin-right: 10px;
      }
    }
  }
}

// common classe
.form-field-list-details {
  .name {
    font-weight: 500;
  }
  .tagline {
    opacity: 0.65;
    margin-top: 1px;
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 0;
  }
}